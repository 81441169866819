"use client"
import Image from "next/image"
import Link from "next/link"
import { AnchorButton } from "@local/ui/src/Button"
import { useEffect } from "react"
import * as Sentry from "@sentry/nextjs"
import Error from "next/error"

export default function GlobalError({ error }: { error: Error }) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  // Sentry.captureException("Could not send qp conversion")

  return (
    <div role="main" className="mx-auto mt-32 max-w-sm px-4 text-center">
      <Image
        src="/images/error.svg"
        alt="sad computer"
        className="mx-auto text-center"
        width={179}
        height={163}
      />
      <h2 className="font-display my-2 text-2xl font-bold">
        Whoops! Looks like something went wrong
      </h2>
      <p className="mb-4 text-sm">
        We&apos;re not sure what happened, but please go back to the home page
        to continue your journey
      </p>
      <Link href="/" passHref legacyBehavior>
        <AnchorButton href="/">Take me back</AnchorButton>
      </Link>
      <Error statusCode={undefined as any} />
    </div>
  )
}
